<template>
  <sdCards>
    <template v-slot:title>
      <div class="setting-card-title">
        <sdHeading as="h4">Cookie Details</sdHeading>
        <span>Set Up Your Cookie Information</span>
      </div>
    </template>
    <BasicFormWrapper>
      <sdHeading as="h4">Cookie Popup Details</sdHeading>
      <a-form :model="form" :rules="rules" @submit="onCookieDetailsSubmit">
        <a-row :gutter="25" style="margin-bottom: 26px">
          <a-col :xl="6" :lg="6" :xs="24">
            <a-form-Item label="Accept Button Text">
              <a-input
                required
                name="accept_button_text"
                placeholder="Button text"
                v-model:value="form.accept_button_text"
              />
            </a-form-Item>
          </a-col>
          <a-col :xl="6" :lg="6" :xs="24">
            <a-form-Item label="Accept Button Background">
              <a-input
                required
                name="accept_button_background"
                placeholder="Button background color"
                v-model:value="form.accept_button_background"
              />
            </a-form-Item>
          </a-col>
          <a-col :xl="6" :lg="6" :xs="24">
            <a-form-Item label="Accept Button Font Color">
              <a-input
                required
                name="accept_button_font_color"
                placeholder="Button font color"
                v-model:value="form.accept_button_font_color"
              />
            </a-form-Item>
          </a-col>
          <a-col :xl="6" :lg="6" :xs="24">
            <a-form-Item label="Accept Button Font Size">
              <a-input
                required
                name="accept_button_font_size"
                placeholder="Button font size"
                v-model:value="form.accept_button_font_size"
              />
            </a-form-Item>
          </a-col>
        </a-row>
        <a-row :gutter="25" style="margin-bottom: 26px">
          <a-col :xl="6" :lg="6" :xs="24">
            <a-form-Item label="Cancel Button Text">
              <a-input
                required
                name="cancel_button_text"
                placeholder="Button text"
                v-model:value="form.cancel_button_text"
              />
            </a-form-Item>
          </a-col>
          <a-col :xl="6" :lg="6" :xs="24">
            <a-form-Item label="Cancel Button Background">
              <a-input
                required
                name="cancel_button_background"
                placeholder="Button background color"
                v-model:value="form.cancel_button_background"
              />
            </a-form-Item>
          </a-col>
          <a-col :xl="6" :lg="6" :xs="24">
            <a-form-Item label="Cancel Button Font Color">
              <a-input
                required
                name="cancel_button_font_color"
                placeholder="Button font color"
                v-model:value="form.cancel_button_font_color"
              />
            </a-form-Item>
          </a-col>
          <a-col :xl="6" :lg="6" :xs="24">
            <a-form-Item label="Cancel Button Font Size">
              <a-input
                required
                name="cancel_button_font_size"
                placeholder="Button font size"
                v-model:value="form.cancel_button_font_size"
              />
            </a-form-Item>
          </a-col>
        </a-row>
        <a-row :gutter="25" style="margin-bottom: 26px">
          <a-col :xl="6" :lg="6" :xs="24">
            <a-form-Item label="Popup Background Color">
              <a-input
                required
                name="popup_background_color"
                placeholder="Background Color"
                v-model:value="form.popup_background_color"
              />
            </a-form-Item>
          </a-col>
          <a-col :xl="6" :lg="6" :xs="24">
            <a-form-Item label="Popup Opacity">
              <a-input
                required
                name="popup_opacity"
                placeholder="Opacity Level"
                v-model:value="form.popup_opacity"
              />
            </a-form-Item>
          </a-col>
          <a-col :xl="6" :lg="6" :xs="24">
            <a-form-item name="cookie_logo" label="Cookie Logo">
              <a-input
                type="file"
                id="meta-file"
                accept="image/*"
                v-on:change="onImageChange($event, 'cookie_logo')"
              />
            </a-form-item>
            <div v-if="otherData.cookie_logo_url" class="img-thumb-cls">
              <img alt="Cookie Logo" :src="otherData.cookie_logo_url" />
            </div>
          </a-col>
        </a-row>
        <a-row v-if="form.cookie_content" style="margin-bottom: 26px">
          <a-col :xs="24" :lg="24">
            <a-form-item name="cookie_content" label="Cookie Content">
              <ckeditor
                :editor="editor"
                :config="editorConfig"
                v-model="form.cookie_content"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <div class="setting-form-actions text-center">
          <sdButton size="default" htmlType="submit" type="primary">
            Update
          </sdButton>
        </div>
      </a-form>
    </BasicFormWrapper>
  </sdCards>
</template>

<script>
import { mapActions } from "vuex";
import { defineComponent } from "vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { BasicFormWrapper } from "../../../styled";
import Notif from "@/services/Notification";

const Cookie = defineComponent({
  name: "Cookie",
  components: { BasicFormWrapper },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {},
      form: {
        cookie_content: "",
        accept_button_text: "",
        accept_button_background: "",
        accept_button_font_color: "",
        accept_button_font_size: "",
        cancel_button_text: "",
        cancel_button_background: "",
        cancel_button_font_color: "",
        cancel_button_font_size: "",
        popup_background_color: "",
        popup_opacity: "",
        cookie_logo: null,
      },
      otherData: {
        cookie_logo_url: "",
      },
      rules: {},
    };
  },
  computed: {
    user: function () {
      return this.$store.getters.authUser;
    },
  },
  watch: {
    user: {
      handler(newUserData) {
        this.handleCookieData(newUserData);
      },
      deep: true,
    },
  },
  beforeMount() {},
  methods: {
    ...mapActions(["updateAdminCookie","getAdminProfile"]),
    handleCancel(e) {
      e.preventDefault();
    },
    async onCookieDetailsSubmit(e) {
      e.preventDefault();
      try {
        await this.updateAdminCookie(this.form);
      } catch (error) {
        if (error?.message) {
          Notif.show(error?.message, { type: "error", title: "Error" });
        }
      }
    },
    onImageChange(e, type) {
      const file = e?.target?.files[0];
      const blobURL = URL.createObjectURL(file);
      if (type == "cookie_logo") {
        this.otherData.cookie_logo_url = blobURL;
        this.form.cookie_logo = file;
        return null;
      }
    },
    async getCookiesData() {
      try {
        await this.getAdminProfile();
      } catch (error) {
        if (error?.message) {
          Notif.show(error?.message, { type: "error", title: "Error" });
        }
      }
    },
    handleCookieData(newUserData) {
      try {
        const {cookie_content = '' , accept_button_text = "",
        accept_button_background = '', accept_button_font_color = "",
        accept_button_font_size = '', cancel_button_text = "",
        cancel_button_background = '', cancel_button_font_color = "",
        cancel_button_font_size = '', popup_background_color = "",
        popup_opacity = '', cookie_logo = ""
        } = newUserData;
        this.form = {
          ...this.form,
          accept_button_text: accept_button_text,
          accept_button_background: accept_button_background,
          accept_button_font_color: accept_button_font_color,
          accept_button_font_size: accept_button_font_size,
          cancel_button_text: cancel_button_text,
          cancel_button_background: cancel_button_background,
          cancel_button_font_color: cancel_button_font_color,
          cancel_button_font_size: cancel_button_font_size,
          popup_background_color: popup_background_color,
          popup_opacity: popup_opacity,
          cookie_content: cookie_content,
        };
        this.otherData.cookie_logo_url = cookie_logo;
      } catch (error) { }
    },
  },
  created() {
    this.getCookiesData();
    // const rules = {};
    // for (const [key] of Object.entries(this.form)) {
    //   rules[key] = [
    //     {
    //       required: true,
    //       message: `Please enter ${key.replace("_", " ")}`,
    //       trigger: "blur",
    //     },
    //   ];
    // }
    // this.rules = rules;
  },
});

export default Cookie;
</script>
